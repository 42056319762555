export default {
  // 學生註冊
  nameZh: '中文名 Chinese Name or Full Name',
  nameEN: '英文名',
  lastName: '姓(拼音形式) Surname',
  firstName: '名(拼音形式) First Name',
  gender: '性別 Gender',
  female: '女 Female',
  male: '男 Male',
  title: '稱謂 Title',
  birthday: '出生日期 Birthday',
  phoneNumber: '學生聯系電話  Student Contact Number',
  phoneRegion: '區號 Area Code ',
  email: '電子郵件 Email',
  emailPassword: '電子郵件密碼 Email Password',
  country: '國家或地區 Country or Region of Residence',
  state: '省 Province',
  city: '市 City',
  region: '區 District',
  postcode: '郵政編碼 Postal Code',
  address: '聯系地址 Contact Address',
  alternativeAddr: '備選聯系地址 Alternative Contact Address',
  fillApply: '保存並填寫入學申請',
  returnStuList: '返回列表',
  fkAreaRegion: '區域 Region',
  saveTemporal: "信息待完善，暫存",
  moreEmailTips:' (多個郵箱請用（；+空格）隔開)',
  viewStudentsAddApplications:'查看學生並進行加申操作',
  duplicateStudentTips:'偵測到，重複學生有{num}個！',
  viewStudentsEditThem:'查看學生並進行編輯操作',
  shareLink:'共享連結',
  notStudentEmail:'(非學生電郵 Not Student Email)',

  // 學生資料管理
  isLivedSinceBirth: '是否自出生便在這個國家或地區居住？Have you lived in this country or region since birth?',
  fkStudentAppCountryIdLive: '目前所在國家/地區 Current country/region',
  migrationTime: '移居時間 Migration Time',
  stayingRightTime: '取居留權時間 Take the right of abode time',
  isHavePassport: '是否有護照 Do you have a passport',
  fkStudentAppCountryIdPassport: '簽發國家或地區 Issuing Country or Region',
  passportIssueDate: '簽發日期 Issue Date',
  passportIssueExpried: '有效期至 Valid until',
  passportNumber: '護照號碼 Passport Number',
  followUpPhoneCode: '區號 Area Code',
  followUpPhoneNumber1: "跟進聯繫人電話號碼1 Follow up the contact's phone number 1",
  followUpPhoneCode1: '區號1 Area Code 1',
  followUpEmail1: '跟進人電郵1 Follow Up Email 1',
  followUpPhoneNumber2: "跟進聯繫人電話號碼2 Follow up the contact's phone number 2",
  followUpPhoneCode2: '區號2 Area Code 2',
  followUpEmail2: '跟進人電郵2 Follow Up Email 2',
  followUpPhoneNumber3: "跟進聯繫人電話號碼3 Follow up the contact's phone number 3",
  followUpPhoneCode3: '區號3 Area Code 3',
  followUpEmail3: '跟進人電郵3 Follow Up Email 3',
  passportInfo: '護照信息 Passport Information',
  contactPerson: '聯系負責人 Contact Person in Charge',
  contactNotice: '(建議提供顧問老師手機號碼，以便收取系統推送學生個案進展)',
  atLeastOne: '(至少填寫一個) (Fill in at least one)',
  fkStudentAppCountryId: '學生國籍/地區 Student Nationality',
  fkStudentAppAncestryId: '學生種族 Student Lineage',
  fkStudentAppMotherLanguageId: '學生母語 Student\'s native language',
  fkStudentAppCountryIdBirth: '出生國家或地區 Country or Region of Birth',
  cityBirth: '出生城市 Birth City',

  regStartTime: '入檔開始時間',
  regEndTime: '入檔結束時間',
  export: '導出',
  applicationStatus: '申請目前處理狀態',
  failureReason: '失敗原因',
  regGmtCreate: '入檔時間',
  edit: '編輯申請',
  addApplyCourse: '加申課程',
  personInfo: '個人信息',
  applyFollow: '申請跟進情況',
  allDataRegiest: '完整資料註冊',
  baseDataRegiest: '註冊基本資料',
  intelligentIdentificationStudentInfo: '智能識別學生資料',
  intelligentIdentificationTips: '通過OCR & NLP識別技術，提取圖像、文檔中的關鍵信息，簡化資料錄入工作',
  uploadPassportNational: '上傳其他地區護照圖片',
  uploadPassport: '上傳中國大陸護照圖片',
  uploadBackground: '上傳背景資料',
  continuetofill: '繼續填寫',
  stuName: '姓名（中文）',
  stuNameEn: '姓名（英文）',
  stuGender: '性別',
  stuNation: '民族',
  stuBirth: '出生日期',
  stuAddr: '聯系地址',
  stuIdcard: '身份證',
  stuBirthPlace: '出生地',
  stuPassport: '護照號碼',
  stuEduExp: '教育經歷',
  stuWorkExp: '工作經歷',
  notUpload: '未上傳',
  stepStatusChangeStartTime: '步驟狀態變更開始時間',
  stepStatusChangeEndTime: '步驟狀態變更結束時間',
  fkItemChangeStepName: '變更步驟',
  fkItemChangeStepNameTips:'變更步驟過濾條件必須填寫變更步驟和變更時間',
  // 文件下載
  studentInformation: '學生資料',

  courseApplyStatus: '申請狀態',
  courseApplyStatus0: '新建',
  courseApplyStatus1: '已提交',
  courseApplyStatus2: '補件',
  courseApplyStatus3: '加申',
  courseApplyStatus4: '顧問已提交',
  // 學生資料管理
  student: '學生',
  fkAreaCountryName: '國家/地區',
  schoolName: '學校名稱',
  courseName: '課程名稱',
  lastBmsApplyFollowStatusName: '課程申請最新狀態',
  submitInfoStatus: '資料提交狀態',
  editApplyStatus: '修改申請狀態',
  completed: '已完成',
  unCompleted: '未完成',
  current: '當前',
  notStart: '未啟動',
  currentHighestApplicationStatus:'目前最高申請狀態：',

  // 學生申請匯總
  studentName: '學生姓名',
  studentNameZh: '學生姓名(中)',
  studentNameEn: '學生姓名(英)',
  applySchoolName: '申請學校',
  applyCourseName: '申請課程',
  courseType: '課程類型',
  bmsApplyFollowStatusName: '申請步驟狀態',
  applyTotalCount: '申請資料遞交總數',
  openingTime: '入學時間（月）',
  notISSUEStudent: '非ISSUE創建學生',
  checkIssueStudentInfo: '查看issue學生信息',

  bindOnlineForm: '綁定在線表單',
  bindSuccess: '綁定成功',
  bindErr: '綁定失敗請檢查數據',
  inputOnlineForm: '請輸入在線表單編號',
  inputOnlineFormErr: '請檢查輸入格式',
  noSelectTips: '選擇不到可進行手寫輸入 No matching selection, please input the name',
  batchTransferStudentManagementAccounts:'批量轉學生管理帳號',
  createAccount:'建立帳號',
  currentManagementAccount:'目前管理帳號',
  transferManagementAccount:'轉移管理帳號',
  transferTips:'學生轉移後，目前帳號將看不到轉移的學生。 除非目前帳號是轉帳帳號的父級帳號。',
  noTransferData:'暫無下屬帳號可選擇',
}
